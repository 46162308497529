
.topnav {
  background-color: #fff;
  box-shadow: 0 3px 8px -4px rgba(0, 0, 0, .08), 0 -4px 14px 0 rgba(0, 0, 0, .05);;
  position: relative;
  z-index: 10;
  /*border-bottom: 1px solid #30373d;*/
  display: flex;
  justify-content: space-between;
  padding: 1rem 0.75rem 1rem 0.75rem;
  align-items: center;
}

.navlogo, .navlinks a {
font-weight: 500;
}

.main {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  padding: 2rem 0;
  overflow: hidden;
}

.navlogo {
  font-size: 1.25rem;
  width: 20rem;
  margin-left: 1rem;
}

.navlinks {
  width: 20rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.modelselector {
  width: 20rem;
  display: flex;
  justify-content: space-evenly;
  align-items: center;
}

.modelselector p {
  margin-right: 0.5em;
}

@media (max-width: 600px) {
  .topnav {
      border: 1px solid black;
      align-items: center;
      padding: 0.85rem 0.75rem 0.85rem 0.75rem;
  }
}
.header {
  width: auto;
}

.header p {
  text-align: center;
}

.apptitle {
  font-size: 2.5rem;
  font-weight: 500;
  display: flex;
  justify-content: center;
}

.appdescription {
  font-size: 1.1rem;
  margin: 1rem;
}

.link {
  font-weight: 500;
}

.cloudform {
  position: relative;
  padding: 6px 0px;
  align-items: center;
}

.textarea {
  position: relative;
  resize: none;
  font-size: 1.1rem;
  padding: 1rem 2rem 1rem 2rem;
  width: 75vw;
  border-radius: 0.5rem;
  border: 1px solid #ccc;
  background: #fff;
  color: #101828;
  outline: none;
}

.selectInput {
  position: relative;
  font-size: 0.8rem;
  padding: 0.2rem 0.5rem 0.2rem 0.5rem;
  width: 20vw;
  border-radius: 0.5rem;
  border: 1px solid #ccc;
  background: #fff;
  color: #101828;
  outline: none;
}

.textarea:disabled {
  opacity: 0.5;
}

.textarea::placeholder {
  color: #5f6368;
}

.generatebutton {
  position: absolute;
  align-items: center;
  top: 23%;
  height: 50%;
  right: 0.8rem;
  justify-content: center;
  color: #eee;
  background: #4e38ed;
  padding: 0.5rem;
  border: none;
  border-radius: 0.3rem;
  display: flex;
}

.loadingwheel {
  position: absolute;
  top: 0.2rem;
  right: 0.25rem;
}

.svgicon {
  transform: rotate(90deg);
  width: 1.2em;
  height: 1.2em;
  fill: currentColor;
}

.generatebutton:hover {
  background: #6178f4;
  color: #eee;
  border-radius: 0.2rem;
}

.generatebutton:disabled {
  opacity: 0.9;
  cursor: not-allowed;
  background: none;
}

.messagelist {
  width: 100%;
  height: 100%;
  overflow-y: scroll;
  border-radius: 0.5rem;
}

.messagelistloading {
  display: flex;
  width: 100%;
  justify-content: center;
  margin-top: 1rem;
}

.usermessage {
  background: #fff;
  padding: 1.5rem;
  color: #000;
}

.usermessagewaiting{
  padding: 1.5rem;
  color: #000;
  background: linear-gradient(to left, #f2f4f7, #ddd , #f2f4f7);
  background-size: 200% 200%;
  background-position: -100% 0;
  animation: loading-gradient 2s ease-in-out infinite;
  animation-direction: alternate;
  animation-name: loading-gradient;
}

@keyframes loading-gradient {
  0% {
    background-position: -100% 0;
  }
  100% {
    background-position: 100% 0;
  }
}

.apimessage {
  background: #f2f4f7;
  padding: 1.5rem;
  color: #101828;
  animation: fadein 0.5s;
}

.likemessage {
  border-top: 1px solid #f6f6f6;
  background: #f2f4f7;
  padding: 0.5rem 1rem;
  color: #101828;
  animation: fadein 0.5s;
  justify-content: center;
}

.likemessage .btn {
  color: #6178f4;
  background: white;
  padding: 0.5rem 0rem;
  margin: 0 0.5em;
}

.likemessage .btnok {
  color: white !important;
  background: #e3e3ff;
  padding: 0.5rem 0rem;
  margin: 0 0.5em;
}

.likemessage .btn .disabled {
  color: white; /*hsl(220, 3%, 79%);*/
  background: white;
}

.likemessage .btn:hover {
  color: white;
  background: #6178f4;
}


@keyframes fadein {
  from { opacity: 0; }
  to   { opacity: 1; }
}

.apimessage, .usermessage, .usermessagewaiting, .likemessage {
  display: flex;
}

.markdownanswer {
  line-height: 1.75;
}

.likefield {
  line-height: 0.5;
}

.markdownanswer a:hover {
  opacity: 0.8;
}

.markdownanswer a {
  color: #16bed7;
  font-weight: 500;
}

.markdownanswer pre code {
  background: #f4f4f4;
  border: 1px solid #ddd;
  border-left: 3px solid #f36d33;
  color: #666;
  page-break-inside: avoid;
  font-family: monospace;
  font-size: 15px;
  line-height: 1.6;
  margin-bottom: 1.6em;
  max-width: 100%;
  overflow: auto;
  padding: 1em 1.5em;
  display: block;
  word-wrap: break-word;
  white-space: pre-wrap !important;
}

.markdownanswer p code {
  background-color: #f4f4f4;
  color: #f36d33;
  border: 1px solid #ddd;
  border-radius: 0.3em;
  padding: 4px 5px 6px;
  white-space: nowrap;
}

.markdownanswer ol, .markdownanswer ul {
  margin: 1rem;
}

.boticon, .usericon {
  margin-right: 1rem;
  border-radius: 0.1rem;
}

.markdownanswer h1, .markdownanswer h2, .markdownanswer h3 {
  font-size: inherit;
}


.center {
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  padding: 2rem 0 0 0;
  flex-direction: column;
}

.cloud {
  width: 75vw;
  height: 68vh;
  background: #fff;
  border-radius: 0.5rem;
  border: 1px solid #ccc;
  display: flex;
  justify-content: center;
  align-items: center;
  overflow: hidden;
}

.pointsnormal {
  width: 90%;
  height: 90%;
}

.pointsdim {
  width: 90%;
  height: 90%;
  opacity: 0.25;
}

.footer {
  color: #101828;
  font-size: 0.8rem;
  margin: 0.5rem;
  text-align: center;
}

.footer a {
  font-weight: 500;
  color: #101828;
}

.footer a:hover {
  opacity: 0.8;
}

/* Mobile optimization */
@media (max-width: 600px) {

  .main {
    padding: 1rem 0;
    max-height: 80vh;
  }

  .cloud {
    width: 22rem;
    height: 28rem;
  }
  .textarea {
    width: 22rem;
  }

.markdownanswer code {
  white-space : pre-wrap !important;
}

.footer {
  font-size: 0.7rem;
  width: 100%;
  text-align: center;
}
}